import plan1 from '../../assets/plan1.jpg';
import plan2 from '../../assets/plan2.jpg';
import plan3 from '../../assets/plan3.jpg';

const PLANS_DATA = [
	{
		id: 1,
		title: 'Professional',
		imageUrl: plan1,
		description: 'Ideal para profesionales o pequeños negocios que inician en servicios digitales',
		service1: 'Marketing en Redes Sociales',
		activities1: [
			{
				id: 1,
				activity: 'Configuracion / Optimizacion de las Redes Sociales Clave'
			},
			{
				id: 2,
				activity: 'Mantenimiento de Perfil'
			},
			{
				id: 3,
				activity: 'Publicar contenido clave (frecuencia segun plataforma)'
			},
			{
				id: 4,
				activity: 'Video y Fotografia personalizada'
			}
		],
		service2: 'Optimizacion de Motores de Busqueda (SEO)',
		activities2: [
			{
				id: 1,
				activity: 'Analisis de Palabras Clave'
			},
			{
				id: 2,
				activity: 'Auditorias Mensuales de Busqueda'
			},
			{
				id: 3,
				activity: 'Optimizacion de Busqueda Local'
			},
			{
				id: 4,
				activity: 'Optimizacion de Palabras Clave en Pagina Web'
			},
			{
				id: 5,
				activity: 'Reportes de Google Analytics'
			}
		],
		service3: 'Blog, Paginas Web y Creacion de Contenido',
		activities3: [
			{
				id: 1,
				activity: 'Diseño de Contenido y Revision'
			},
			{
				id: 2,
				activity: 'Diseño y Estructura de Pagina Web/Blog'
			},
			{
				id: 3,
				activity: 'Publicacion personalizada en blog de acuerdo a la industria'
			},
			{
				id: 4,
				activity: 'Analisis de Contenido(en todas las platformas)'
			},
			{
				id: 5,
				activity: 'Actualizaciones Mensuales'
			}
		]
	},
	{
		id: 2,
		title: 'Business',
		imageUrl: plan2,
		description: 'Para Negocios o Profesionales que buscan Incrementar su Base de Clientes',
		service1: 'Marketing en Redes Sociales',
		activities1: [
			{
				id: 1,
				activity: 'Incluye Plan Professional +'
			},
			{
				id: 2,
				activity: 'Distribucion de Contenido a las Redes Sociales'
			},
			{
				id: 3,
				activity: 'Interaccion con Audiencia'
			},
			{
				id: 4,
				activity: 'Reportes de Visitas & Rating'
			}
		],
		service2: 'Optimizacion de Motores de Busqueda (SEO)',
		activities2: [
			{
				id: 1,
				activity: 'Incluye Plan Professional +'
			},
			{
				id: 2,
				activity: 'Enlaces Dirigidos'
			},
			{
				id: 3,
				activity: 'Envio de Sitios a Motores de Busqueda'
			},
			{
				id: 4,
				activity: 'Seguimiento de hasta 30 Palabras Clave'
			}
		],
		service3: 'Blog, Paginas Web y Creacion de Contenido',
		activities3: [
			{
				id: 1,
				activity: 'Incluye Plan Professional +'
			},
			{
				id: 2,
				activity: 'Etiquetado de Palabras Clave con Metadatos incluidos'
			},
			{
				id: 3,
				activity: 'Optimizacion y Actualizacion de Pagina Web'
			},
			{
				id: 4,
				activity: 'Soporte en Linea y Seguimiento de Desempeño'
			}
		]	
	},
	{
		id: 3,
		title: 'Enterprise',
		imageUrl: plan3,
		description: 'Negocios que requieren de Mayor Soporte para Incrementar su Alcance con Herramientas Digitales',
		service1: 'Marketing en Redes Sociales',
		activities1: [
			{
				id: 1,
				activity: 'Incluye Plan Business +'
			},
			{
				id: 2,
				activity: 'Subir videos en youtube con Palabras Clave Completamente Optimizadas'
			},
			{
				id: 3,
				activity: 'Notas o Comentarios Clave en Video'
			},
			{
				id: 4,
				activity: 'Crear / Limpiar el Perfil de la Empresa en Sitios de Comentarios'
			}
		],
		service2: 'Optimizacion de Motores de Busqueda (SEO)',
		activities2: [
			{
				id: 1,
				activity: 'Incluye Plan Business +'
			},
			{
				id: 2,
				activity: 'Seguimiento de hasta 100 Palabras Clave'
			},
			{
				id: 3,
				activity: 'Marcadores de Contenido Clave en Redes'
			},
			{
				id: 4,
				activity: 'Enlaces Dirigidos y Analisis de Palabras Clave'
			}
		],
		service3: 'Blog, Paginas Web y Creacion de Contenido',
		activities3: [
			{
				id: 1,
				activity: 'Incluye Plan Business +'
			},
			{
				id: 2,
				activity: 'Actualizacion Diaria de Contenido'
			},
			{
				id: 3,
				activity: 'Ping en Sitios Clave de Contenido Nuevo'
			},
			{
				id: 4,
				activity: 'Pruebas y Mantenimiento Optimizado de Sitio Web'
			}
		]
	}
]

export default PLANS_DATA;