import service1 from '../../assets/service1.jpg';
import service2 from '../../assets/service2.jpg';
import service3 from '../../assets/service3.jpg';

const SERVICES_DATA = [
	{
		id: 1,
		title: 'Desarrollo Web & Apps',
		imageUrl: service1,
		description: 'Diseño y actualización de tu pagina web o app con la teconologia mas moderna',
		activities: [
			{
				id: 1,
				name:'Diseño Web: Modernos y Adaptables diseños',
				description: 'Las mas actuales tendencias en diseño para la pagina web de tu negocio'
			},
			{
				id: 2,
				name: 'Desarrollo Web: Rendimiento y Seguridad',
				description: 'Desarollo continuo con los mas altos estandares de seguridad y velocidad'
			},
			{
				id: 3,
				name: 'Tienda en linea (e-commerce): Tu tienda digital con todas las funciones actuales',
				description: 'Desarrollo completo y actualizado de acuerdo con las necesidades comerciales'
			},
			{
				id: 4,
				name:'Alojamiento y Servidores: Administración de la estructura de tu sitio web',
				description: 'El hospedaje de tu pagina web es la base mas importante para cuidar de tu sitio'
			},
			{
				id: 5,
				name: 'Aplicacion Web y movil: Todo lo que necesitas al alcance de tu mano',
				description: 'La teconologia tiene que ser accesible y segura'
			}
		]
	},
	{
		id: 2,
		title: 'Marketing Digital',
		imageUrl: service2,
		description: 'Toda las herramientas para mantenerte donde clientes buscan por tus productos / servicios',
		activities: [
			{
				id: 1,
				name:'SEO',
				description: 'Optimizacion adecuada para que tu negocio este en los resultados de busqueda'
			},
			{
				id: 2,
				name: 'Estrategia en Redes Sociales',
				description: 'Creacion de valor a traves de las plataformas de redes sociales'
			},
			{
				id: 3,
				name: 'Creacion de contenido y Fotografia',
				description: 'El contenido debe estar acorde a la estrategia de marketing y del negocio'
			},
			{
				id: 4,
				name:'Video Marketing',
				description: 'Tus productos o servicios se venden mejor cuando los clientes saben como funcionan'
			},
			{
				id: 5,
				name: 'Estrategia de Marketing',
				description: 'Tu estrategia de negocio es diferente y requiere un enfoque para que llegue a tus clientes potenciales'
			}
		]
	},
	{
		id: 3,
		title: 'Datos y Analisis',
		imageUrl: service3,
		description: 'Los datos que son importantes para medir y mejorar tu negocio',
		activities: [
			{
				id: 1,
				name:'Creacion de Bases de Datos y Optimizacion',
				description: 'El diseño de la estructura es importante para que el analisis sea facil y rapido'
			},
			{
				id: 2,
				name: 'Analisis de Datos',
				description: 'Los reportes y datos importantes que necesitas observar con frecuencia'
			},
			{
				id: 3,
				name: 'Business Intelligence',
				description: 'Las mejores decisiones para mejorar tu negocio se toman con datos confiables'
			},
			{
				id: 4,
				name:'Seguridad',
				description: 'La principal prioridad en el manejo de los datos de tu negocio'
			},
			{
				id: 5,
				name: 'Machine Learning',
				description: 'Automatizar la recoleccion de datos y analisis es importante para el futuro de tu negocio'
			}
		]
	}
]

export default SERVICES_DATA;